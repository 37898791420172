.BlogTitle{
    font-size: 50px;
    color: #226389;
}
.blog{
    min-height: 82vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (max-width: 768px) {
    .BlogTitle{
        font-size: 30px;
    }

}