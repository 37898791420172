@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');
.federal-frontier {
  margin: 0 auto;
  padding: 20px;
  font-family: 'IBM Plex Sans', sans-serif;
  max-width: 100%;
  background-color: white;
}

.federal-frontier-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 15px; /* Increased border-radius for a cooler look */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow for depth */
  flex-wrap: wrap;
}

.federal-frontier-text {
  flex: 1;
  padding: 20px; /* Add spacing within the text section */
  text-align: left;
  animation-name: slideInLeft;
  animation-duration: 1.5s;
  animation-delay: 0.25s; /* Delay the animation */
  animation-fill-mode: both; /* Keeps the end state after animation */
}
@keyframes slideInLeft {
  from {
    transform: translateX(-150%); /* Start position, off-screen to the left */
  }
  to {
    transform: translateX(0); /* End position, on-screen */
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(150%); /* Start position, off-screen to the right */
  }
  to {
    transform: translateX(0); /* End position, on-screen */
  }
}
.federal-frontier-image {
  max-width: 50%;
  max-height: 50%;
  animation-name: slideInRight;
  animation-duration: 1.5s;
  animation-delay: 0.25s; /* Delay the animation */
  animation-fill-mode: both; /* Keeps the end state after animation */
}

.federal-frontier-image img {
  width: 100%;
  height: 100%;
  border-radius: 25px; /* Increased border-radius for consistency */
}

.federal-frontier-text h1 {
  font-size: 40px;
  color: #226389;
}

.federal-frontier-text h2 {
  font-size: 20px;
  margin-top: 20px;
  line-height: 1.5;
  color: #696767;
}

.federal-frontier-text ul {
  list-style-type: disc;
  padding-left: 20px;
}

.federal-frontier-text p {
  font-size: 18px;
  margin: 20px 0;
  line-height: 1.5;
}
/* Style for the drop-down sections */
.row {
  display: flex;
  justify-content: space-between;
  animation-name: popUp;
  animation-duration: 1.5s;
  animation-delay: .25s; /* Delay the animation */
  animation-fill-mode: both; /* Keeps the end state after animation */
  margin-bottom: 10px;
}
@keyframes popUp {
  from {
    opacity: 0; /* Start with 0 opacity (invisible) */
    transform: translateY(50px); /* Start position, move down 50px */
  }
  to {
    opacity: 1; /* End with full opacity (visible) */
    transform: translateY(0); /* End position, normal position */
  }
}

.section {
  width: 25%; /* 25% width for each section with some margin */
  height: 50px; /* Set a fixed height */
  margin: 10px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  text-align: left;
  transition: transform 0.3s;
  cursor: pointer;
}
.section.open {
  height: 150px; /* This removes the maximum height, allowing it to expand to its full height */
  line-height: 1.3;
  text-decoration: none;
}


.summary {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  color: #226389;
}

.details p {
  font-size: 16px;
  margin-top: 10px;
  color: #696767;
  font-weight: bold;
  text-decoration: none;

}

/* Hover effect */
.section:hover {
  transform: translateY(-35px);
}


.learn-more{
    display: flex;
    justify-content: space-evenly;
}

/* FederalFrontier.css */

/* Existing styles for other sections */

/* "How It Works" Section */
.how-it-works {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  flex-direction: column;
  margin-top: 80px;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 40px;
}

.how-it-works-text {
  flex: 1; /* Take up the left column */
  margin-bottom: 50px;
  border-radius: 50px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.how-it-works-image {
  padding-bottom: 50px;
}

.how-it-works-image img {
  width: 100%;
  height: 100%;
  border-radius: 25px; /* Increased border-radius for consistency */
}


.how-it-works h2 {
  font-size: 75px; /* Example font size */
  margin-bottom: 20px; /* Add margin to separate the title from the content */
  color: #226389;
  letter-spacing: .05em;
}

.how-it-works p {
  font-size: 25px; /* Example font size */
  color: #226389; /* Example text color */
}
/* Add these CSS styles to your stylesheet */

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure the modal is on top of other content */
}

/* Modal */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

/* Modal Image (for controlling the image size) */
.modal-image {
  max-width: 90vw; /* Adjust as needed to control image size */
  max-height: 90vh; /* Adjust as needed to control image size */
}

/* Media query for smaller screens - one section per row */
@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
  }

  .section {
    width: 100%; /* One section per row */
    height: 50px; /* Allow content to determine height */
  }
  .federal-frontier-image{
      max-width: 100%;
      max-height: 100%;
    }
    .how-it-works h2 {
          font-size: 40px;
          margin-bottom: 20px;
          color: white;
          letter-spacing: .05em;
    }
    .how-it-works p {
      font-size: 20px;
      color: white;
      padding: 10px;
    }
    .how-it-works-text {
        margin-bottom: 0;
    }
    .how-it-works-image img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .how-it-works-image {
      padding: 10px 10px;
    }
    .how-it-works {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        flex-direction: column;
        margin-top: 0;
        background-color: #226389;
        border-radius: 15px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
        margin-bottom: 40px;;
    }
    .federal-frontier-text h1 {
      font-size: 30px;
    }
    .federal-frontier-text h2 {
      font-size: 18px;
      margin-top: 0;
      line-height: 1.5;
      color: #696767;
    }

    .federal-frontier-text p {
      font-size: 15px;
      margin: 20px 0;
      line-height: 1.5;
    }

}

/* Additional styling for the "How It Works" section as needed */
