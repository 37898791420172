/* Solutions styles */
.solutions-section {
  padding: 50px 0;
  text-align: center;
  background-color: #f7f7f7;
}

.solutions-heading {
  font-size: 2rem;
  margin-bottom: 20px;
}

.solutions-text {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.solutions-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.solution-item {
  font-size: 1.1rem;
  margin-bottom: 10px;
}
