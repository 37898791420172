/* Navigation bar styles */
nav {
  background-color: black;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.logo {
  display: flex;
  align-items: center;
  float: left;
}

.logo img {
  width: 300px; /* Adjust the width as needed */
  height: auto;
  margin-right: 10px; /* Add some spacing between the logo and text */
}

/* Default styles for desktop */
.nav-links {
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0;
  padding: 0;
}

.mainNavLink {
  position: relative; /* Make the li container relative for absolute positioning */
}

.mainNavLinkText {
  position: relative;
  color: #fff;
  text-decoration: none;
  padding-bottom: 5px; /* Add space between text and underline */
}

.mainNavLinkText::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #666;
  transition: width 0.5s ease-in-out;

}

.mainNavLinkText:hover::before {
  width: 100%; /* Expand the underline to the width of the text */
}



.mainNavLinkText:hover {
  color: #226389;
  transition: color .5s ease-in-out; /* Add transition for width */
}

/* Add these styles for the dropdown */
ul.submenu {
  list-style: none;
  display: flex;
  position: absolute;
  top: 50px; /* Adjust the top value for spacing */
  left: 50%; /* Center the dropdown under the parent li */
  transform: translateX(-50%); /* Center the dropdown horizontally */
  width: 200px; /* Adjust the width as needed */
  background-color: black; /* Background color of the dropdown */
  padding: 10px;
  border-radius: 10px; /* Add border-radius for rounded edges */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  opacity: 0; /* Start with opacity set to 0 */
  visibility: hidden; /* Start with visibility hidden */
  transition: opacity 0.5s, visibility 0.5s; /* Add transitions for smoother appearance/disappearance */
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
}

li:hover ul.submenu {
  opacity: 1; /* Set opacity to fully visible when hovered */
  visibility: visible; /* Set visibility to visible when hovered */
}

li ul.submenu li {
  padding: 5px;
  width: 100%;
  text-align: left;
}

/* Style for the submenu links */
li ul.submenu a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 5px; /* Add some padding for spacing */
  position: relative;
}

/* Style for the submenu link text */
li ul.submenu .subLinkText {
  position: relative;
}
.subLinkText{
    padding-bottom: 5px;
}

/* Style for the submenu link underline */
li ul.submenu .subLinkText::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #666;
  transition: width 0.5s ease-in-out;

}
/* Expand the underline's width to match the width of the submenu link text on hover */
li ul.submenu a:hover .subLinkText::before {
  width: 100%; /* Expand the width of the underline on hover */
}

li ul.submenu a:hover .subLinkText {
  color: #226389;
  transition: color .5s ease-in-out; /* Add transition for width */
}






/* Media query for mobile screens */
@media (max-width: 768px) {
  .logo {
    justify-content: center; /* Center the logo */
  }
  .logo img {
    width: 150px; /* Adjust the width as needed */
    height: auto;
  }

.nav-links {
    display: none; /* Hide the navigation links by default */
    flex-direction: column; /* Stack the links vertically */
    background-color: black;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 52px; /* Adjust the top position as needed */
    left: 0;
    transition: all 0.3s ease;
    padding: 10px 0; /* Add some padding for spacing */
    z-index: -1111; /* Ensure the dropdown is above other elements */
  }

  ul.active {
    display: block; /* Show the navigation links when active class is added */
    z-index: 999;
  }
  li {
    margin: 5px 0; /* Add spacing between each link */
  }

  .menu-icon {
    cursor: pointer;
    font-size: 1.5rem;
  }
}