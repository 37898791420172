/* Styles for the FleetEdgeAir component */

.fleet-edge-air {
  padding: 20px;
  background-color: #1f1f1f;
  font-family: 'IBM Plex Sans', sans-serif;
}

.fleet-edge-air-overview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.9);
  flex-wrap: wrap;
  margin-bottom: 60px;
  border: 2px solid #226389; /* Add a border style */
  height: 454px;
}

.fleet-edge-air-header {
  flex: 1;
  padding: 20px; /* Add spacing within the text section */
  text-align: left;
  animation-name: slideInLeft;
  animation-duration: 1s;
  animation-fill-mode: both; /* Keeps the end state after animation */
}

/* Define the animations */
@keyframes slideInLeft {
  from {
    transform: translateX(-150%); /* Start position, off-screen to the left */
  }
  to {
    transform: translateX(0); /* End position, on-screen */
  }
}

.fleet-edge-air-overview h1 {
  font-size: 50px;
  color: #226389;
  margin: 0;
}

.fleet-edge-air-overview p {
  font-size: 20px;
  color: #226389;
  font-weight: 500;
  line-height: 1.5;
  margin: 50px 0;
}

.fleet-edge-air-detailed-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background-color: #1f1f1f;
  border: 1px solid black;
  border-radius: 25px;
  overflow: hidden;
  background-color: #666;
  margin: 20px;
  animation: fadeInUp 1s ease-in-out;
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.9);
}

/* Define the animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fleet-edge-air-explanation {
  flex: 1;
  padding: 20px;
  text-align: left;
  background-color: #666;
}

.fleet-edge-air-explanation h2 {
  font-size: 40px;
  margin-bottom: 10px;
  color: white;
}

.fleet-edge-air-explanation p {
  font-size: 25px;
  margin-bottom: 20px;
  color: white;
}

.fleet-edge-air-image-logo {
  max-width: 40%;
  animation-name: slideInRight;
  animation-duration: 1s;
  animation-fill-mode: both; /* Keeps the end state after animation */
}

.fleet-edge-air-text-logo {
  font-size: 50px;
  color: #666;
  font-family: Monaco;
  font-style: oblique;
  text-align: right;
}

.fleet-edge-air-image {
  flex: 1;
  background-color: #226389;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
  width: 100%;
}

.fleet-edge-air-image img {
  max-width: 100%;
  width: 90%;
  height: 90%;
  border-radius: 25px;
}



/* Add more styles for benefits and configurations as needed */
.fleet-edge-air-benefits-section h2 {
  font-size: 50px;
  color: #226389;
  text-align: center; /* Center the heading */
  margin-bottom: 50px;
}

.fleet-edge-air-benefits-list {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 40px;
}

.fleet-edge-air-benefits-list li {
  font-size: 24px;
  color: white;
  margin-bottom: 10px;
}

.benefit-icon {
  text-align: center;
  justify-content: center;
  width: 550px;
  padding: 0 20px;
}

.benefit-icon img {
  max-width: 100px;
}

.benefit-icon h3 {
  font-size: 30px;
  color: #226389;
  margin-top: 30px;
  margin-bottom: 30px;
}
.benefit-icon p {
  font-size: 20px;
  color: #666;
  margin-bottom: 30px;
  margin-top: 5px;
}
.FE-features-header {
    font-size: 50px;
    color: #226389;
    text-align: center;
    margin-bottom: 50px;
}
.FE-row {
    display: flex;
    justify-content: space-between;
    animation-name: popUp;
    animation-duration: 1.5s;
    animation-delay: .25s; /* Delay the animation */
    animation-fill-mode: both; /* Keeps the end state after animation */
    margin-bottom: 10px;
}
.FE-section.open {
  height: 150px; /* This removes the maximum height, allowing it to expand to its full height */
  line-height: 1.3;
  text-decoration: none;
  background-color: #484848;
}
.FE-section {
  width: 25%; /* 25% width for each section with some margin */
  height: 50px; /* Set a fixed height */
  margin: 10px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  text-align: left;
  transition: transform 0.3s;
  cursor: pointer;
}
.FE-details p {
  font-size: 14px;
  margin-top: 10px;
  color: antiquewhite;
  font-weight: bold;
  text-decoration: none;
}
.FE-section:hover {
  transform: translateY(-35px);
}
.FE-features-div {
  border: 2px solid #226389; /* Customize the border color and size as needed */
  padding: 20px; /* Add some padding inside the border */
  border-radius: 10px; /* Optional: Adds rounded corners to the border */
  margin: 20px; /* Add some margin outside the border */
  /* Other existing styles */
}

.FE-technical-specifications {
    border: 2px solid #226389; /* Customize the border color and size as needed */
      padding: 20px; /* Add some padding inside the border */
      border-radius: 10px; /* Optional: Adds rounded corners to the border */
      margin: 20px;
}

.FE-technical-specifications-header {
    font-size: 50px;
        color: #226389;
        text-align: center;
        margin-bottom: 50px;
}

.FE-technical-specifications-paragraph {
    font-size: 17px;
      margin-top: 10px;
      color: #666;
      font-weight: bold;
      text-decoration: none;
}

.FE-summary {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
    color: #226389;
}

.FE-learn-more-button {
    background-color: #226389;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color .3s ease-in-out;
}

@media (max-width: 768px) {
    .fleet-edge-air-detailed-section {
      flex-direction: column;
    }
    .fleet-edge-air-image-logo {
      max-width: 75%;
      max-height: 75%;
      display: flex;
      justify-content: center;
      align-text: center;
    }
    .fleet-edge-air-overview{
        padding: 0;
        justify-content: center;
        height: auto;
    }
    .fleet-edge-air-header {
        text-align: center;
    }
    .fleet-edge-air-header h1 {
        font-size: 40px;
    }
    .fleet-edge-air-header p {
        font-size: 25px;
    }
    .fleet-edge-air-benefits-list {
        flex-wrap: wrap;
    }
    .fleet-edge-air-image {
        max-width: 100%;
        padding: 30px 0;
    }
    .FE-row {
        flex-direction: column;
        align-items: center;
    }
    .FE-section {
        width: 75%;
    }
    .FE-summary {
        margin-top: 0;
    }
    .FE-section.open {
        height: 200px;
    }

    .FE-features-div {
        margin: 0;
    }
    .FE-technical-specifications {
        margin: 10px 0;
    }
    .FE-technical-specifications-header {
        font-size: 40px;
    }
    .FE-learn-more-button {
        font-size: 10px;
        height: 30px;
        padding: 10px;
        width: 50px;
    }

    .fleet-edge-air-image-logo img {
        max-width: 100%;
        max-height: 100%;
    }

}