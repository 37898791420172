/* Team styles */
.team-section {
  padding: 50px 50px;
  text-align: center;
  background-color: #f5f5f5;
  background-image: url('/public/images/team-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 77vh;
}

.team-heading {
  font-size: 60px;
  color: white;
}

.team-text {
  font-size: 40px;
  margin-bottom: 20px;
  padding-bottom: 30px;
  color: white;
}
.team-members{
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
}
.team-member {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 205px;
}

.team-member-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.team-member-name {
  font-size: 1.3rem;
  margin-bottom: 5px;
  color: white;
}

.team-member-role {
  font-size: 1.1rem;
  color: white;
}
@media (max-width: 768px) {

    .team-members {
        flex-wrap: wrap;
    }
    /* Team styles */
    .team-section {
      padding: 50px 50px;
      text-align: center;
      background-color: #f5f5f5;
      background-image: none;
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
    }
    .team-heading {
      font-size: 2rem;
      color: black;
    }
    .team-member-role,
    .team-text {
        color: black;
    }
    .team-member-name {
        color: black
    }
    .team-member{
        border: 3px solid #666;
    }
}