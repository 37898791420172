/* WhyCAPI.css */

/* Styles for the main container */
.why-capi {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 15px; /* Increased border-radius for a rounder appearance */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Added box shadow for depth */
  margin: 20px;
}

/* Title style */
.why-capi-title {
  font-size: 30px; /* Increased font size for titles */
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

/* Styles for each section */
.capi-section {
  margin-bottom: 30px;
  padding: 20px; /* Added padding for separation */
  background-color: #fff; /* Background color for the sections */
  border-radius: 10px; /* Border radius for round appearance */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Added box shadow for section depth */
  text-align: center; /* Align section content to center */
}

/* Section title style */
.capi-section-intro-title {
  font-size: 40px; /* Increased font size for section titles */
  font-weight: bold;
  margin-bottom: 10px;
  color: #226389;
}

/* Section text style */
.capi-section-intro-text {
  font-size: 25px; /* Increased font size for section text */
  line-height: 1.5;
  margin-bottom: 10px;
}

/* List style */
.capi-list {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left; /* Align list content to the left */
}
/* Styling for Section 2: How We Use CAPI */
.usage-section {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.capi-section-usage-title {
  text-align: center;
  font-size: 35px; /* Increase title font size */
  margin-top: 0; /* Remove top margin for title */
  padding-bottom: 10px; /* Add some space below title */
  color: #226389;
}

.usage-content {
  display: flex;
  flex-direction: row;
  align-items: center; /* Center content horizontally */
  gap: 50px;
}

.usage-text {
  text-align: left; /* Align text to the left */
  font-size: 20px; /* Adjust text font size */
  line-height: 1.5;
  margin-bottom: 20px;
}

.usage-image img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-top: 20px; /* Add space above the image */
}
.usage-image {
    min-width: 50%;
}


/* Styling for Section 3: Benefits of CAPI */
.benefits-section {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center; /* Center-align the section */
}

.capi-section-benefits-title {
  font-size: 35px; /* Increase title font size */
  margin-top: 0; /* Remove top margin for title */
  padding-bottom: 10px; /* Add some space below title */
  color: #226389;
}

.capi-section-benefits-text {
  text-align: left; /* Align text to the left */
  font-size: 24px; /* Adjust text font size */
  line-height: 1.5;
  margin-bottom: 15px; /* Add space below text */
}

.capi-benefits-list {
  list-style: disc outside none; /* Use disc bullets for list items */
  padding-left: 20px; /* Add indentation to list items */
  text-align: left; /* Align list items to the left */
  font-size: 20px; /* Adjust list item font size */
  line-height: 1.5;
}
@media screen and (max-width: 768px) {

    .usage-content {
        flex-direction: column;
    }
}