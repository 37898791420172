/* Add your global styles here */

/* Cluster API Security Container */
.cluster-api-security {
  padding: 60px;
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: #f5f5f5;
}


/* Section Container */
.overview-cluster-api-section {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
  padding: 20px;
  border-radius: 5px;
  flex-direction: column;
  text-align: left;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow for depth */
}
.overview-section-cluster-api-header {
  flex: 1;
  color: #226389;
}
.overview-section-cluster-api-header h1 {
    font-size: 50px;
}
.overview-section-cluster-api-content {
  flex: 2;
  padding: 10px;
  font-size: 30px;
}

/* Section Container */
.cluster-api-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 20px;
  border-radius: 5px;
}

/* Header */
.section-cluster-api-header {
  flex: 1;
  padding: 30px 0;
  height: 100%;
  text-align: left;
}

.section-cluster-api-header h2 {
  font-size: 30px;
  margin: 0;
}

/* Content */
.section-cluster-api-content {
  flex: 2;
  padding: 10px;
}

.section-cluster-api-content p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}
/* Header */
.HIW-cluster-api-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0;
    border-radius: 5px;
    gap: 60px;

}
.HIW-section-cluster-api-header {
  flex: 1;
  padding: 30px 0;
  height: 100%;
  text-align: left;
  color: #226389
}

.HIW-section-cluster-api-header h2 {
  font-size: 40px;
  margin: 0;
}
.HIW-section-cluster-api-header p {
  font-size: 20px;
  margin: 0;
  margin-top: 30px;
}
.HIW-cluster-api-image {
  max-width: 60%;
  max-height: 60%;
  animation-name: slideInLeft;
  animation-duration: 1.5s;
  animation-delay: 0.25s; /* Delay the animation */
  animation-fill-mode: both; /* Keeps the end state after animation */
}

.HIW-cluster-api-image img {
  width: 80%;
  height: 100%;
}




/* Style for Section 3: Implementing Cluster API */
.cluster-api-implementation-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 100px 0;
  padding: 20px;
  border-radius: 10px;
  /*background-color: #313334;*/
}

.cluster-api-implementation-text {
  flex: 2;
  padding: 20px;
}
.cluster-api-implementation-text h2 {
  text-align: left;
  font-size: 40px;
  padding: 15px 0;
  color: #226389;
}

.cluster-api-implementation-header {
  flex: 1;
  padding-left: 20px; /* Adjust the spacing between the header and content */
}

.cluster-api-implementation-header h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.cluster-api-implementation-list {
  list-style-type: disc;
  display: flex;
  text-align: left;
  flex-direction: column;
  font-size: 20px;
}

/* Style for list items (optional) */
.cluster-api-implementation-list li {
  margin-bottom: 5px;
}
.cluster-api-image {
  max-width: 50%;
  max-height: 50%;
  animation-name: slideInLeft;
  animation-duration: 1.5s;
  animation-delay: 0.25s; /* Delay the animation */
  animation-fill-mode: both; /* Keeps the end state after animation */
}

.cluster-api-image img {
  width: 80%;
  height: 80%;
}

.ZT-cluster-api-section-both{
    display: flex;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow for depth */
    flex-direction: column;
    margin-bottom: 50px;
}


/* Styling for Section 4: Zero Trust Architecture */
.ZT-cluster-api-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  flex-direction: column;
}

.ZT-section-cluster-api-header {
  flex: 1;
}

.ZT-section-cluster-api-header h2 {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #226389
}

.ZT-section-cluster-api-content {
  flex: 2;
}

.ZT-section-cluster-api-content p {
  font-size: 26px;
  line-height: 1.5;
  text-align: left;
  margin-right: 50%;
}

/* Media query for responsiveness (adjust as needed) */
@media (max-width: 768px) {
  .ZT-cluster-api-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .ZT-section-cluster-api-header {
    padding-left: 0;
    margin-bottom: 10px;
  }
}

/* Styling for Section 4: Zero Trust Architecture */
.TLS-cluster-api-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  flex-direction: column;
}

.TLS-section-cluster-api-header {
  flex: 1;
}

.TLS-section-cluster-api-header h2 {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #226389
}

.TLS-section-cluster-api-content {
  flex: 2;
}

.TLS-section-cluster-api-content p {
  font-size: 26px;
  line-height: 1.5;
  text-align: right;
  margin: 0;
  margin-left: 50%;
}
.sectionS {
  width: 25%; /* 25% width for each section with some margin */
  height: 50px; /* Set a fixed height */
  margin: 10px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  text-align: left;
  transition: transform 0.3s;
  cursor: pointer;
}
.section.openS {
  height: auto; /* This removes the maximum height, allowing it to expand to its full height */
  line-height: 1.3;
  text-decoration: none;
  width: 70%;
}
.summaryS {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #226389;
}
.detailsS p {
  font-size: 16px;
  color: #696767;
  text-decoration: none;
}

@media (max-width: 768px) {
  .cluster-api-security {
    padding: 20px;
  }
  .HIW-cluster-api-section {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 0;
  }
  .HIW-cluster-api-image {
    max-width: 100%;
    max-height: 100%;
  }
  .cluster-api-implementation-section {
    flex-direction: column;
    margin: 0;
    margin-bottom: 50px;
    padding: 0;
  }
  .cluster-api-implementation-list {
    padding: 0;
  }
  .cluster-api-image {
    max-height: 100%;
    max-width: 100%;
  }
  .ZT-section-cluster-api-content p {
      font-size: 26px;
      line-height: 1.5;
      text-align: center;
      margin-right: 0;
  }
  .TLS-section-cluster-api-content p {
        font-size: 26px;
        line-height: 1.5;
        text-align: center;
        margin-left: 0;
    }
  .TLS-section-cluster-api-header h2 {
    margin-top: 0;
  }
}

