/* Documentation styles */
.documentation-section {
  padding: 50px 0;
  text-align: center;
  background-color: #f7f7f7;
}

.documentation-heading {
  font-size: 2rem;
  margin-bottom: 20px;
}

.documentation-text {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.documentation-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #66aaff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.documentation-link:hover {
  background-color: #3377cc;
}
