/* FleetEdgeSea.css */
/* Styles for the FleetEdgeSea component */

.fleet-edge-sea {
  padding: 20px;
  background-color: #1f1f1f;
  font-family: 'IBM Plex Sans', sans-serif;
}

.fleet-edge-sea-overview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.9);
  flex-wrap: wrap;
  margin-bottom: 60px;
  border: 2px solid #226389; /* Add a border style */
  height: 454px;
}

.fleet-edge-sea-header {
  flex: 1;
  padding: 20px; /* Add spacing within the text section */
  text-align: left;
  animation-name: slideInLeft;
  animation-duration: 1s;
  animation-fill-mode: both; /* Keeps the end state after animation */
}

/* Define the animations */
@keyframes slideInLeft {
  from {
    transform: translateX(-150%); /* Start position, off-screen to the left */
  }
  to {
    transform: translateX(0); /* End position, on-screen */
  }
}

.fleet-edge-sea-overview h1 {
  font-size: 50px;
  color: #226389;
  margin: 0;
}

.fleet-edge-sea-overview p {
  font-size: 20px;
  color: #226389;
  font-weight: 500;
  line-height: 1.5;
  margin: 50px 0;
}

.fleet-edge-sea-detailed-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background-color: #1f1f1f;
  border: 1px solid black;
  border-radius: 25px;
  overflow: hidden;
  background-color: #666;
  margin: 20px;
  animation: fadeInUp 1s ease-in-out;
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.9);
}

/* Define the animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fleet-edge-sea-explanation {
  flex: 1;
  padding: 20px;
  text-align: left;
  background-color: #666;
}

.fleet-edge-sea-explanation h2 {
  font-size: 40px;
  margin-bottom: 10px;
  color: white;
}

.fleet-edge-sea-explanation p {
  font-size: 25px;
  margin-bottom: 20px;
  color: white;
}

.fleet-edge-sea-image-logo {
  max-width: 40%;
  animation-name: slideInRight;
  animation-duration: 1s;
  animation-fill-mode: both; /* Keeps the end state after animation */
}

.fleet-edge-sea-image-logo img {
  width: 100%;
  height: 100%;
}

.fleet-edge-sea-image {
  flex: 1;
  background-color: #226389;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
  width: 100%;
}

.fleet-edge-sea-image img {
  max-width: 100%;
  width: 90%;
  height: 90%;
  border-radius: 25px;
}

/* Add more styles for benefits and configurations as needed */
.fleet-edge-sea-benefits-section h2 {
  font-size: 50px;
  color: #226389;
  text-align: center; /* Center the heading */
  margin-bottom: 50px;
}

.fleet-edge-sea-benefits-list {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 40px;
}

.fleet-edge-sea-benefits-list li {
  font-size: 24px;
  color: white;
  margin-bottom: 10px;
}

.benefit-icon {
  text-align: center;
  justify-content: center;
  width: 550px;
  padding: 0 20px;
}

.benefit-icon img {
  max-width: 100px;
}

.benefit-icon h3 {
  font-size: 30px;
  color: #226389;
  margin-top: 30px;
  margin-bottom: 30px;
}

.benefit-icon p {
  font-size: 20px;
  color: #666;
  margin-bottom: 30px;
  margin-top: 5px;
}

.FES-technical-specifications {
    border: 2px solid #226389; /* Customize the border color and size as needed */
          padding: 20px; /* Add some padding inside the border */
          border-radius: 10px; /* Optional: Adds rounded corners to the border */
          margin: 20px;
}

.FES-technical-specifications-header {
     font-size: 50px;
                color: #226389;
                text-align: center;
                margin-bottom: 50px;
}

.FES-technical-specifications-paragraph {
    font-size: 17px;
          margin-top: 10px;
          color: #666;
          font-weight: bold;
          text-decoration: none;
}

@media (max-width: 768px) {
    .fleet-edge-sea-detailed-section {
      flex-direction: column;
    }
    .fleet-edge-sea-image-logo {
      max-width: 75%;
      max-height: 75%;
    }
    .fleet-edge-sea-overview{
        padding: 0;
        justify-content: center;
        height: auto;
    }
    .fleet-edge-sea-header {
        text-align: center;
    }
    .fleet-edge-sea-header h1 {
        font-size: 40px;
    }
    .fleet-edge-sea-header p {
        font-size: 25px;
    }
    .fleet-edge-sea-benefits-list {
        flex-wrap: wrap;
    }
    .fleet-edge-sea-image {
        max-width: 100%;
        padding: 30px 0;
    }
    .FE-row {
        flex-direction: column;
        align-items: center;
    }
    .FE-section {
        width: 75%;
    }
    .FE-summary {
        margin-top: 0;
    }
    .FE-section.open {
        height: 200px;
    }

    .FE-features-div {
        margin: 0;
    }
    .FE-technical-specifications {
        margin: 10px 0;
    }
    .FE-technical-specifications-header {
        font-size: 40px;
    }




}