/* Styles for the FrontierEdge component */

.frontier-edge {
  padding: 20px;
  background-color: #1f1f1f;
  font-family: 'IBM Plex Sans', sans-serif;
}

.frontier-edge-overview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.9);
  flex-wrap: wrap;
  margin-bottom: 60px;
  border: 2px solid #226389; /* Add a border style */
  height: 454px;
}
.frontier-edge-header {
  flex: 1;
  padding: 20px; /* Add spacing within the text section */
  text-align: left;
  animation-name: slideInLeft;
  animation-duration: 1s;
  animation-fill-mode: both; /* Keeps the end state after animation */
}
@keyframes slideInLeft {
  from {
    transform: translateX(-150%); /* Start position, off-screen to the left */
  }
  to {
    transform: translateX(0); /* End position, on-screen */
  }
}
.frontier-edge-overview h1 {
  font-size: 50px;
  color: #226389;
  margin: 0;
}

.frontier-edge-overview p {
  font-size: 20px;
  color: #226389;
  font-weight: 500;
  line-height: 1.5;
  margin: 50px 0;
}

.frontier-edge-detailed-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background-color: #1f1f1f;
  border: 1px solid black;
  border-radius: 25px;
  overflow: hidden;
  background-color: #666;
  margin: 20px;
  animation: fadeInUp 1s ease-in-out;
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.9);
}
/* Define the animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.frontier-edge-explanation {
  flex: 1;
  padding: 20px;
  text-align: left;
  background-color: #666;
}

.frontier-edge-explanation h2 {
  font-size: 40px;
  margin-bottom: 10px;
  color: white;
}

.frontier-edge-explanation p {
  font-size: 25px;
  margin-bottom: 20px;
  color: white;
}
.frontier-edge-image-logo {
  max-width: 40%;
  animation-name: slideInRight;
  animation-duration: 1s;
  animation-fill-mode: both; /* Keeps the end state after animation */
}

.frontier-edge-image-logo img {
  width: 100%;
  height: 100%;
}


.frontier-edge-image {
  flex: 1;
  background-color: #226389;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
  width: 100%;
}

.frontier-edge-image img {
  max-width: 100%;
  width: 90%;
  height: 90%;
  border-radius: 25px;
}


.frontier-edge-benefits-section {
  margin-top: 20px;
  padding: 20px;
  background-color: #1f1f1f;
  border-radius: 15px;
  animation: bounceIn 2s ease-in-out;
  margin: 90px 0;
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.9);
  border: 2px solid #226389; /* Add a border style */
}


@keyframes bounceIn {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  70% {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
}
.frontier-edge-benefits-section h2 {
  font-size: 50px;
  color: #226389;
  text-align: center; /* Center the heading */
  margin-bottom: 50px;
}

.frontier-edge-benefits-list {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 40px;
}

.frontier-edge-benefits-list li {
  font-size: 24px;
  color: white;
  margin-bottom: 10px;
}

.benefit-icon {
  text-align: center;
  justify-content: center;
  width: 550px;
  padding: 0 20px;
}

.benefit-icon img {
  max-width: 100px;
}

.benefit-icon h3 {
  font-size: 30px;
  color: #226389;
  margin-top: 30px;
  margin-bottom: 30px;
}
.benefit-icon p {
  font-size: 20px;
  color: #666;
  margin-bottom: 30px;
  margin-top: 5px;
}

/* Styles for the FrontierEdge component */

/* ... (previous styles) ... */

/* Styles for the "How It Works" section */
.frontier-edge-how-it-works {
  padding: 20px;
  background-color: #D3D3D3;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.9);
  margin: 20px 0;
}

.frontier-edge-how-it-works h1 {
  font-size: 40px;
  color: black;
  margin-bottom: 0;
}

.frontier-edge-how-it-works-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.frontier-edge-how-it-works-image {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.frontier-edge-how-it-works-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.frontier-edge-how-it-works-text {
  padding: 20px;
  text-align: left;
}

.frontier-edge-how-it-works-text h2 {
  font-size: 30px;
  color: #226389;
}

.frontier-edge-how-it-works-text p {
  font-size: 18px;
  color: #226389;
  line-height: 1.5;
}

/* FleetEdgeConfigurations.css */
.fleet-edge-configurations {
  text-align: center;
  padding: 20px;
  background-color: #2F2F2F;
  border-radius: 15px;
  margin: 40px 0;
}

.fleet-edge-configurations h1 {
  font-size: 40px;
  color: #226389;
}

.fleet-edge-configurations-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.fleet-edge-configurations-table {
  flex: 1;
  margin-right: 20px;
  padding: 15px;
}

.fleet-edge-configurations-table table {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #226389; /* Change border color and thickness */
}

.fleet-edge-configurations-table th,
.fleet-edge-configurations-table td {
  border: 1px solid #ccc;
  padding: 15px 25px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #226389; /* Change border color and thickness */
  color: #ABABAB;
}
.fleet-edge-configurations-table th {
  font-size: 20px;
  color: #DDDDDD;
  border-bottom: 2px solid #226389; /* Change border color and thickness */
  border-right: 2px solid #226389; /* Change border color and thickness */
}

.fleet-edge-configurations-text {
  flex: 2;
}

.fleet-edge-configurations-text h2 {
  font-size: 30px;
  color: #226389;
}


.configurations-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.config-item {
  margin-bottom: 20px;
}

.config-subtitle {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: #226389;
}

.config-description {
  font-size: 20px;
  margin-top: 5px;
  text-align: left;
  color: #ABABAB;
}

.config-list {
  list-style-type: disc;
  text-align: left;
  color: #ABABAB;
  font-size: 20px;
}

/* Add more styles as needed */




@media (max-width: 768px) {
    .frontier-edge-detailed-section {
      flex-direction: column;
    }
    .frontier-edge-image-logo {
      max-width: 75%;
      max-height: 75%;
    }
    .frontier-edge-overview{
        padding: 0;
        justify-content: center;
        height: auto;
    }
    .frontier-edge-header {
        text-align: center;
    }
    .frontier-edge-header h1 {
        font-size: 40px;
    }
    .frontier-edge-header p {
        font-size: 25px;
    }
    .frontier-edge-benefits-list {
        flex-wrap: wrap;
    }
    .frontier-edge-how-it-works-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    .frontier-edge-image {
        max-width: 100%;
        padding: 30px 0;
    }
    .frontier-edge-how-it-works-image,
      .frontier-edge-how-it-works-text {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0;
      }
      .frontier-edge-how-it-works-image img {
      }
    .frontier-edge-how-it-works {
        padding: 10px;
    }
    .fleet-edge-configurations {
        max-width: 100%;
        overflow: hidden;
        padding: 0;
    }
    .fleet-edge-configurations-table {
        padding: 0 5px;;
        margin-right: 0;
    }
    .fleet-edge-configurations-table td,
    .fleet-edge-configurations-table th {
        font-size: 9px;
        padding: 2px;
    }
    .fleet-edge-configurations-content {
        flex-wrap: wrap;
    }
}
