

.home-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap; /* Reverse order for smaller screens */
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 50px;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.home-text {
  animation: slideUp 3s ease-in-out;
  max-width: 850px;
}


.home-text h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: white;
}

.home-text p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #666;
}

.explore-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #226389;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.explore-button:hover {
  background-color: #666;
}

.home-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-image img {
  max-width: 100%;
  height: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
/* ... (previous styles) */

.home-3d-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 850px;
}
.home-section {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto; /* Set the height of the home section to the viewport height */
}

.home-background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.our-partners-section {
  background-color: #f5f5f5;
  padding-top: 30px;
}

.our-partners-title {
  font-size: 40px;
  color: #226389;
  padding-bottom: 30px;
  margin: 0;
}

.partner-logo {
  width: 125px;
  height: auto;
}
/* CustomCarousel.css */

.slick-list{
    height: 150px;
}
.slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 150px !important; /* Reset the slide height */
}

.slick-slide img {
  width: 300px;
  height: auto !important;
}
.slick-track{
    display: flex !important;
      justify-content: center;
      align-items: center;
}



@media (max-width: 1189px) {
    .home-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap-reverse; /* Reverse order for smaller screens */
      flex-direction: column;
    }
    .home-3d-container {
      flex: 1;
      display: none;
      justify-content: center;
      align-items: center;
    }
    .our-partners-section {
        padding-top: 20px;
    }
    .our-partners-title{
        margin: 0;
        font-size: 25px;
        padding: 0;
    }
    .slick-slide {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .slick-slide img {
      width: 100px;
      height: auto !important;
    }
    .slick-list{
        height: 100px;
    }
    .slick-track{
        height: 100px;
    }
}